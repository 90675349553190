import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  Checkbox,
  DateRangeFilterButton,
  MultiSelectFilter,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import InsightDownloadCsv from '../common/InsightDownloadCsv';
import OverallCards from '../common/OverallCards';
import TabHeader from '../common/TabHeader';
import {
  dateRangeToTimestamp,
  numbersToStringWithCommas,
  stringifyDateRange,
} from '../helpers';
import { InsightSortDirection } from '../types';
import {
  initialInsightTopicParams,
  InsightTopicSortColumns,
} from './constants';
import {
  applyShowOnlyFilter,
  applyWorkflowFilter,
  defaultWorkflowSelectOptions,
  extractChildItems,
} from './helper';
import TopicsTable from './TopicsTable';
import { InsightTopicData, InsightTopicDataColumnKeys } from './types';
import isEqual from 'lodash/fp/isEqual';
import partition from 'lodash/fp/partition';
import filterIcon from 'src/assets/images/filter-analytic-icon.svg';
import { useEmitTrackingEventCallback, useStateParams } from 'src/hooks/hooks';
import { NA } from 'src/pages/intent-conversation-analytics/constants';
import { useIntents } from 'src/pages/workflow-builder/intent-workflows-table/hooks/useIntents';
import {
  useGetChatsSavingsQuery,
  useGetTopicsTableQuery,
} from 'src/services/insights';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  dateRangeToTimeFilter,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { CommonIntentWorkflowType, ExportableTableType } from 'src/utils/enums';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

const deserializeInitialSelected = (initialSelected: string | null) => {
  const workflowId = initialSelected?.split('workflow_id.')[1];

  return workflowId;
};

const SolveInsightsTopics = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { palette } = useTheme();
  // State params
  const [sortDirection] = useStateParams<InsightSortDirection>({
    deserialize: (param: string) => (param === 'ASC' ? 'ASC' : 'DESC'),
    initialState: 'DESC',
    paramsName: 'topic_sort_direction',
    serialize: (state: InsightSortDirection) => state.toString(),
  });
  const [sortColumn, setSortColumn] = useStateParams<InsightTopicSortColumns>({
    deserialize: (param: string) => param as InsightTopicSortColumns,
    initialState: initialInsightTopicParams.topicColumnSort,
    paramsName: 'topics_sort_column',
    serialize: (state: InsightTopicSortColumns) => state.toString(),
  });
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: initialInsightTopicParams.date,
    paramsName: 'date',
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator([]),
  });
  const [isTopicGrouped, setIsTopicGrouped] = useStateParams<boolean>({
    deserialize: (value: string) => value === 'true',
    initialState: true,
    paramsName: 'is_grouped',
    serialize: (value: boolean) => value.toString(),
    validator: (param: string) => param === 'true' || param === 'false',
  });
  const timestamps = dateRangeToTimestamp(dateRange);
  const { data: savingCardData, isLoading: isSavingCardDataLoading } =
    useGetChatsSavingsQuery({
      end_timestamp: timestamps.end_timestamp,
      start_timestamp: timestamps.start_timestamp,
      topics_only: true,
    });
  const { data: allSavingCardData, isLoading: isAllSavingCardDataLoading } =
    useGetChatsSavingsQuery({
      end_timestamp: timestamps.end_timestamp,
      start_timestamp: timestamps.start_timestamp,
      topics_only: false,
    });

  // Hooks
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const [visibleColumns, setVisibleColumns] = useState<
    InsightTopicDataColumnKeys[]
  >(initialInsightTopicParams.topicColumns);

  const [showOnlyFilter, setShowOnlyFilter] = useState<string[]>([]);
  const { intents } = useIntents();

  const [workflowFilter, setWorkflowFilter] = useState<string[]>(
    params.get('initial_selected')
      ? [
          intents.find(
            intent =>
              intent.intent_workflow_id ===
              deserializeInitialSelected(params.get('initial_selected')),
          )?.intent_definition_id as string,
        ].filter(Boolean)
      : [],
  );

  const { data: topicTableData, isLoading: isTopicTableLoading } =
    useGetTopicsTableQuery({
      data_export_type: ExportableTableType.INSIGHT_TOPICS_TABLE,
      end: timestamps.end_timestamp,
      sort_column: sortColumn,
      sort_direction: sortDirection,
      start: timestamps.start_timestamp,
    });

  const isLoading =
    isTopicTableLoading ||
    isSavingCardDataLoading ||
    isAllSavingCardDataLoading;

  const timeFilter = dateRangeToTimeFilter(dateRange);
  const initialTimeFilter = dateRangeToTimeFilter(
    initialInsightTopicParams.date,
  );

  const areFiltersUsed = Boolean(
    timeFilter.key !== initialTimeFilter.key ||
      workflowFilter.length ||
      showOnlyFilter.length ||
      visibleColumns.toString() !==
        initialInsightTopicParams.topicColumns.toString() ||
      sortColumn !== initialInsightTopicParams.topicColumnSort,
  );

  const costPerTicket = savingCardData?.misc.cost_per_ticket.toString() ?? NA;

  const treatedTableData = useMemo(() => {
    if (isLoading) {
      const fillerTopic: InsightTopicData = {
        chats_count: 0,
        cluster_id: '',
        cluster_name: '',
        csat: 0,
        deflected_chats_count: 0,
        deflected_rate: 0,
        display_name: '',
        engagement_rate: 0,
        insight_subtopics: [],
        non_deflected_chats_count: 0,
        potential_saving: 0,
        realized_saving: 0,
        related_articles: [],
        type: 'workflow',
        workflows: [],
      };
      // use empty data to render loading state
      return Array(10).fill(fillerTopic) as InsightTopicData[];
    }

    if (topicTableData?.data.length) {
      const data = isTopicGrouped
        ? topicTableData?.data
        : extractChildItems(topicTableData?.data);

      const filtered = applyShowOnlyFilter(
        showOnlyFilter,
        applyWorkflowFilter(workflowFilter, data),
      );
      // Sort the sub topics
      const sortedTopicTableData = filtered.map(item => {
        const sortedInsightSubTopics = [...item.insight_subtopics].sort(
          (a, b) => (a.chats_count > b.chats_count ? -1 : 1),
        );
        return { ...item, insight_subtopics: sortedInsightSubTopics };
      });

      // move Others subtopic to be at the bottom of the subtopics
      const organizedTableData = sortedTopicTableData.map(topic => {
        const subtopics = topic.insight_subtopics;
        const [othersSubtopic, normalSubtopics] = partition(
          subtopic => subtopic.cluster_name.endsWith(' - Others'),
          subtopics,
        );

        return {
          ...topic,
          insight_subtopics: normalSubtopics.concat(othersSubtopic),
        };
      });

      return organizedTableData;
    }
    return [];
  }, [
    isLoading,
    topicTableData,
    workflowFilter,
    showOnlyFilter,
    isTopicGrouped,
  ]);

  const getTotalChatsFromTable = treatedTableData.reduce((acc, val) => {
    return acc + val.chats_count;
  }, 0);
  // Get all conversation count
  const getTotalChatsFromSavingCard = allSavingCardData?.misc.chats ?? 0;
  const percentCoverage =
    getTotalChatsFromSavingCard === 0
      ? NA
      : numbersToStringWithCommas({
          number: getTotalChatsFromTable / getTotalChatsFromSavingCard,
          style: 'percent',
        });

  const workflowSelectOptions = intents.length
    ? intents.map(intent => ({
        label: intent.intent_name,
        value: intent.intent_definition_id,
      }))
    : defaultWorkflowSelectOptions;

  const getTopicsTableDownloadUrl = useCallback(() => {
    const url = new URL(`${API_URL}data-export`);
    return url.href;
  }, []);

  const hasFallback = intents.some(
    intent => intent.intent_definition_id === CommonIntentWorkflowType.FALLBACK,
  );

  const countAllTopics = () => {
    if (!topicTableData) {
      return 0;
    }

    if (!isTopicGrouped) {
      return treatedTableData.length;
    }

    return treatedTableData.reduce((prev, current) => {
      return prev + current.insight_subtopics.length;
    }, 0);
  };

  return (
    <Box
      bgcolor={palette.colors.white}
      display='flex'
      flexDirection='column'
      gap={3}
      height='100%'
      overflow='auto'
      padding='24px 40px'
    >
      <TabHeader
        dateRange={stringifyDateRange(dateRange)}
        scope='main'
        tabName='Topics'
      />
      <OverallCards dateRange={dateRange} tab='topic' />
      <Typography
        color={palette.colors.grey[600]}
        variant='font16'
      >{`Topics created for ${percentCoverage} of Chats. Chats need to contain sufficient dialogue length to be classified as a Topic category.`}</Typography>
      <Box>
        <TopicsTable
          costPerTicket={costPerTicket}
          data={treatedTableData}
          emitTrackingEventCallback={emitTrackingEventCallback}
          filters={
            <Box
              alignItems='center'
              display='flex'
              gap='8px'
              paddingTop='4px'
              width='100%'
            >
              <DateRangeFilterButton
                explicitLabel={true}
                initialValue={initialInsightTopicParams.date}
                onChange={setDateRange}
                options={datePickerRangeOptionsRevamp}
                value={dateRange}
              />
              <MultiSelectFilter
                icon={<img src={filterIcon} />}
                isMenuSearchable
                onChange={value => {
                  setWorkflowFilter(value);
                }}
                options={workflowSelectOptions}
                placeholder='Filter by'
                value={workflowFilter}
              />
              {hasFallback && (
                <Tooltip tooltipContent="Chats trigger ‘Fallback’ when they can't answer a question due to insufficient knowledge articles or workflow gaps">
                  <Checkbox
                    checked={isEqual(workflowFilter, [
                      CommonIntentWorkflowType.FALLBACK,
                    ])}
                    label='Gaps only'
                    onChange={event => {
                      setWorkflowFilter(
                        event.target.checked
                          ? [CommonIntentWorkflowType.FALLBACK]
                          : [],
                      );
                    }}
                  />
                </Tooltip>
              )}
              <Checkbox
                checked={isTopicGrouped}
                label='Group Topics'
                onChange={event => {
                  setIsTopicGrouped(event.target.checked);
                }}
              />

              {areFiltersUsed && (
                <Button
                  onClick={() => {
                    setDateRange(initialInsightTopicParams.date);
                    setVisibleColumns(initialInsightTopicParams.topicColumns);
                    setSortColumn(initialInsightTopicParams.topicColumnSort);
                    setWorkflowFilter([]);
                    setShowOnlyFilter([]);
                  }}
                  size='medium'
                  variant='ghost'
                >
                  <Typography noWrap variant='font14Medium'>
                    Reset filters
                  </Typography>
                </Button>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-end',
                  paddingRight: 2,
                }}
              >
                <InsightDownloadCsv
                  filename={'chats.csv'}
                  requestData={{
                    data_export_type: ExportableTableType.INSIGHT_TOPICS_TABLE,
                    end: timestamps.end_timestamp,
                    start: timestamps.start_timestamp,
                  }}
                  url={getTopicsTableDownloadUrl()}
                />
              </Box>
            </Box>
          }
          isLoading={isTopicTableLoading}
          isTopicGrouped={isTopicGrouped}
          searchParams={
            workflowFilter.length === 1
              ? `&initial_selected=workflow_id.${
                  intents.find(
                    intent => intent.intent_definition_id === workflowFilter[0],
                  )?.intent_workflow_id
                }`
              : ''
          }
          totalTopicsCount={countAllTopics()}
        />
      </Box>
    </Box>
  );
};

export default SolveInsightsTopics;
