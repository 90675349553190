import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

import { Tabs } from '@forethought-technologies/forethought-elements';
import { TABS } from './constants';
import SimulationLabelsTab from './SimulationLabelsTab';
import SimulationTicketsTab from './SimulationTicketsTab';
import NavbarV2 from 'src/components/app/navbar/navbarV2';
import Spinner from 'src/components/spinner';
import { useGetModelByIdQuery } from 'src/services/triage/triageApi';
import { Routes } from 'src/utils/enums';

const TriageSimulationsDetailPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const modelId = searchParams.get('model_id');
  const [tab, setTab] = useState(0);
  const { data: model, isLoading } = useGetModelByIdQuery(modelId ?? '');

  const handleOnGoBack = () => {
    navigate(Routes.TRIAGE_SIMULATION_RUNS);
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (!model) {
    return null;
  }

  const CurrentTab = [SimulationLabelsTab, SimulationTicketsTab][tab];

  return (
    <Box bgcolor='white' height='100%'>
      <NavbarV2
        currentTabOverride={model.model_name}
        onGoBack={handleOnGoBack}
      />

      <Box display='flex' flexDirection='column' px='24px' rowGap={4}>
        <Tabs
          a11yProps={index => {
            return {
              'aria-controls': `predictions-tabpanel-${index}`,
              id: `predictions-tab-${index}`,
            };
          }}
          aria-label='labels and tickets'
          onChange={(_, index) => setTab(index)}
          tabs={TABS}
          typographyVariant='font16'
          value={tab}
        />
        <CurrentTab model={model} modelId={modelId ?? ''} />
      </Box>
    </Box>
  );
};

export default TriageSimulationsDetailPage;
