import { useTheme } from '@mui/material';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useConnectors } from '../integrations-page/hooks';
import { lastSync } from './helpers';
import { useGetHelpdeskQuery } from 'src/services/triage/triageApi';

const AvatarIcon = styled('img')`
  height: 24px;
  width: 24px;
`;

const HelpDesk = () => {
  const { filteredCreatedConnectors, isLoading } = useConnectors('');
  const { data, isLoading: isLoadingHelpDesk } = useGetHelpdeskQuery();
  const { palette } = useTheme();

  if (isLoading || isLoadingHelpDesk) {
    return <Skeleton width={100} />;
  }

  if (!data) {
    return null;
  }

  const activeConnector = filteredCreatedConnectors.find(
    item => item.connector_definition.integration_type === data.helpdesk,
  );

  if (!activeConnector) {
    return null;
  }

  return (
    <Box alignItems='center' columnGap={1} display='flex'>
      <AvatarIcon src={activeConnector.connector_definition.avatar} />
      <Box display='flex' flexDirection='column'>
        <Typography color={palette.colors.black} variant='font14Medium'>
          {activeConnector.connector_definition.name}
        </Typography>
        <Typography color={palette.colors.grey[700]} variant='font14'>
          Last sync: {lastSync(activeConnector.last_processed_at)}
        </Typography>
      </Box>
    </Box>
  );
};

export default HelpDesk;
