import { TriageModelLabel } from '../types';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';

export const filterSearchLabels = (
  data: TriageModelLabel[],
  searchText: string,
) =>
  data.filter(
    label =>
      label.name.toLowerCase().includes(searchText.toLowerCase()) ||
      label.description.toLowerCase().includes(searchText.toLowerCase()),
  );

export const createLabelsFirstLoadingData = (
  model: VersionedTriageModel,
  searchText: string,
) => {
  const data = model.labels.map(label => ({
    accuracy: null,
    actual_count: null,
    correct_count: null,
    coverage: null,
    description: label.description,
    finalized_count: null,
    last_update_at: label.updated_at?.toString() || '',
    last_updated_by: '',
    name: label.title,
    pending_count: null,
    predicted_count: null,
    prediction_count: null,
    qualified_count: 0,
    recall: null,
    tag_id: label.tag_id,
  }));

  if (!searchText) {
    return data;
  }

  return filterSearchLabels(data, searchText);
};
