import React, { type ComponentProps } from 'react';
import Box from '@mui/material/Box';

import { MultiSelectFilter } from '@forethought-technologies/forethought-elements';
import filterIcon from 'src/assets/images/filter-analytic-icon.svg';

const FilterSelect = ({
  disabled,
  icon,
  maxHeight,
  menuTitle = 'Filter by',
  onChange,
  options,
  placeholder,
  value,
}: {
  disabled?: boolean;
  icon?: React.ReactNode;
  maxHeight?: number;
  menuTitle?: string;
  onChange: (value: string[]) => void;
  options: ComponentProps<typeof MultiSelectFilter>['options'];
  placeholder?: string;
  value: string[];
}) => {
  return (
    <Box
      sx={{
        '&>div': {
          opacity: disabled ? '0.5' : undefined,
        },
        pointerEvents: disabled ? 'none' : undefined,
      }}
    >
      <MultiSelectFilter
        icon={icon || <img src={filterIcon} />}
        isMenuSearchable={true}
        maxHeight={maxHeight}
        menuTitle={menuTitle}
        onChange={onChange}
        options={options}
        placeholder={menuTitle || placeholder || ''}
        value={value}
      />
    </Box>
  );
};

export default FilterSelect;
