import {
  addPercentageValue,
  numToPercentage,
  numToString,
} from '../triage-model-detail-page/helpers';
import { OverviewMapValue } from '../triage-model-detail-page/types';
import { LabelMetrics, LabelOverallMetric } from 'src/services/triage/types';

export const TABS = ['Labels', 'Tickets'];

export const labelOverviewInfoMap: Record<
  keyof LabelOverallMetric,
  OverviewMapValue
> = {
  accuracy: {
    formatValue: numToPercentage,
    key: 'Accuracy',
    tooltip:
      "This indicates the accuracy of Triage's label predictions. The calculation formula is Correct Predictions/Finalized tickets x 100",
  },
  correct_predictions_count: {
    formatValue: numToString,
    key: 'Correct predictions',
    tooltip:
      'Tickets with correct and finalized predictions. If agents did not override the labels and the ticket status is either closed or resolved, the Triage prediction is considered accurate.',
  },
  predictions_count: {
    formatValue: addPercentageValue,
    key: 'Predicted tickets',
    tooltip: 'Tickets where Triage successfully predicted and assigned a label',
  },
  total_tickets_count: {
    formatValue: numToString,
    key: 'Total tickets',
    tooltip: 'Total number of tickets that Forethought has access to.',
  },
};

export const labelTableInfoMap: Record<
  Exclude<keyof LabelMetrics, 'description' | 'accurate_count' | 'label_name'>,
  OverviewMapValue
> = {
  accuracy: {
    formatValue: numToPercentage,
    key: 'Accuracy',
    tooltip:
      "This indicates the accuracy of Triage's label predictions. The calculation formula is Correct Predictions/Finalized tickets x 100",
  },
  finalized_tickets_count: {
    formatValue: numToString,
    key: 'Finalized tickets',
    tooltip: 'Predicted tickets which are close or resolved.',
  },
  predictions_count: {
    formatValue: addPercentageValue,
    key: 'Predicted tickets',
    tooltip: 'Tickets where Triage successfully predicted and assigned a label',
  },
  recall: {
    formatValue: numToPercentage,
    key: 'Recall',
    tooltip: '',
  },
};

export const TICKET_SEARCH_TYPE_OPTIONS = [
  {
    text: 'Ticket',
    value: 'keyword',
  },
  {
    text: 'Ticket ID',
    value: 'ticket_id',
  },
];
