import { useCallback, useState } from 'react';
import Box from '@mui/material/Box';

import {
  INITIAL_SEARCH_TYPE,
  PARAM_SEARCH_NAME,
  PARAM_SEARCH_TYPE,
  SEARCH_TYPE_OPTIONS,
} from './constants';
import EmptyTicketsTable from './EmptyTicketsTable';
import TicketsOverview from './TicketsOverview';
import TicketsTable from './TicketsTable';
import {
  DISCOVER_SHARED_PARAM_NAMES,
  timeFilterOptions,
} from 'src/constants/discover';
import { useStateParams } from 'src/hooks/hooks';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { useGetTicketsTableQuery } from 'src/services/triage/triageApi';
import { DateRange } from 'src/types/types';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { datePickerRangeOptions } from 'src/utils/timeRangeHelpers';

const TicketsTab = ({
  model,
  modelId,
}: {
  model: VersionedTriageModel;
  modelId: string;
}) => {
  const [filters, setFilters] = useState<string[]>([]);

  const [searchType, setSearchType] = useStateParams({
    deserialize: item => item,
    initialState: INITIAL_SEARCH_TYPE,
    paramsName: PARAM_SEARCH_TYPE,
    serialize: item => item,
  });
  const [searchText, setSearchText] = useStateParams({
    deserialize: item => item,
    initialState: '',
    paramsName: PARAM_SEARCH_NAME,
    serialize: item => item,
  });
  const [page, setPage] = useState(1);
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: datePickerRangeOptions[2].value,
    paramsName: DISCOVER_SHARED_PARAM_NAMES.TIME_FILTER,
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator(timeFilterOptions),
  });
  const keywordSearch = searchType === 'keyword' ? searchText : undefined;
  const ticketId = searchType === 'ticket_id' ? searchText : undefined;
  const { data, isFetching, isLoading } = useGetTicketsTableQuery({
    endDate: Number(dateRange.to),
    filters,
    keywordSearch,
    modelId,
    page,
    startDate: Number(dateRange.from),
    ticketId,
  });

  const handleLoadMore = useCallback(() => {
    if (data?.next_page) {
      setPage(data.next_page);
    }
  }, [data?.next_page]);

  const handleDateRangeChange = async (value: DateRange) => {
    setPage(1);
    setDateRange(value);
  };

  const handleSearch = async (text: string) => {
    setPage(1);
    setSearchText(text);
  };

  const handleSearchTypeChange = (value: string) => {
    setPage(1);
    setSearchType(value, () => setSearchText(''));
  };
  const handleFilterChange = async (values: string[]) => {
    setPage(1);
    setFilters(values);
  };

  const tableLoading = isLoading || isFetching;
  const noTickets = !data?.tickets.length;
  const grid =
    noTickets && !tableLoading ? 'auto 110px auto' : 'auto minmax(350px, auto)';
  const ticketsData = data?.tickets;

  return (
    <Box display='grid' gridTemplateRows={grid} rowGap={5}>
      <TicketsOverview
        dateRange={dateRange}
        filters={filters}
        keywordSearch={keywordSearch}
        modelId={modelId}
        page={page}
        ticketId={ticketId}
        tickets={ticketsData}
      />
      <TicketsTable
        dateRange={dateRange}
        filters={filters}
        hasNextPage={Boolean(data?.next_page)}
        isFetching={isFetching}
        isLoading={isLoading || (page === 1 && isFetching)}
        model={model}
        onDateRangeChange={handleDateRangeChange}
        onFilterChange={handleFilterChange}
        onLoadMore={handleLoadMore}
        onSearch={handleSearch}
        onSearchTypeChange={handleSearchTypeChange}
        searchOptions={SEARCH_TYPE_OPTIONS}
        searchText={searchText}
        searchType={searchType}
        tickets={ticketsData}
      />
      {noTickets && !tableLoading && <EmptyTicketsTable />}
    </Box>
  );
};

export default TicketsTab;
