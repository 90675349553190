import OverviewHeader from '../triage-model-detail-page/common/OverviewHeader';
import { DataItem } from '../triage-model-detail-page/common/OverviewItem';
import { labelOverviewInfoMap } from './constants';
import { NA } from 'src/constants/solve';
import { LabelOverallMetric } from 'src/services/triage/types';
import { DateRange } from 'src/types/types';

const SimulationLabelsOverview = ({
  data,
  dateRange,
}: {
  data: LabelOverallMetric;
  dateRange: DateRange;
}) => {
  const overviewData = Object.keys(data)
    .map(key => {
      const typedKey = key as keyof LabelOverallMetric;
      const value = data[typedKey];
      const target = labelOverviewInfoMap[typedKey];
      if (!key || !target) {
        return null;
      }

      return {
        key: target.key,
        tooltip: target.tooltip,
        value: value ? target.formatValue(value) : NA,
      };
    })
    .filter((item): item is DataItem => item !== null);

  return (
    <OverviewHeader
      data={overviewData}
      dateRange={dateRange}
      gridTemplateColumns='1fr 1fr 1fr 1fr'
      title='All labels overview'
    />
  );
};

export default SimulationLabelsOverview;
