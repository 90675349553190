import { useMemo, useState } from 'react';
import { createMRTColumnHelper, MRT_Row } from 'material-react-table';

import TextWithInfo from '../triage-model-detail-page/common/TextWithInfo';
import TriageModelTable from '../triage-model-detail-page/common/TriageModelTable';
import ValueCell from '../triage-model-detail-page/common/ValueCell';
import { labelTableInfoMap } from './constants';
import { LabelMetrics } from 'src/services/triage/types';

interface LabelsTableProps {
  data: LabelMetrics[];
  isDataLoading: boolean;
}

const SIMULATION_LABEL_COLUMNS: {
  key: keyof LabelMetrics;
  size?: number;
  title: string;
  tooltip: string | null;
}[] = [
  {
    key: 'label_name',
    size: 300,
    title: 'Labels',
    tooltip: null,
  },
  {
    key: 'description',
    size: 400,
    title: 'Description',
    tooltip: null,
  },
  {
    key: 'predictions_count',
    size: 240,
    title: labelTableInfoMap.predictions_count.key,
    tooltip: labelTableInfoMap.predictions_count.tooltip,
  },
  {
    key: 'finalized_tickets_count',
    size: 240,
    title: labelTableInfoMap.finalized_tickets_count.key,
    tooltip: labelTableInfoMap.finalized_tickets_count.tooltip,
  },
  {
    key: 'recall',
    size: 150,
    title: labelTableInfoMap.recall.key,
    tooltip: labelTableInfoMap.recall.tooltip,
  },
  {
    key: 'accuracy',
    size: 150,
    title: labelTableInfoMap.accuracy.key,
    tooltip: labelTableInfoMap.accuracy.tooltip,
  },
];

const percentages: Array<Partial<keyof LabelMetrics>> = ['accuracy', 'recall'];

const SimulationLabelsTable = ({ data, isDataLoading }: LabelsTableProps) => {
  const [searchText, setSearchText] = useState('');

  const columnHelper = createMRTColumnHelper<LabelMetrics>();
  const columns = useMemo(() => {
    return SIMULATION_LABEL_COLUMNS.map(item => {
      return columnHelper.accessor(item.key, {
        Cell: ({ row }: { row: MRT_Row<LabelMetrics> }) => {
          return (
            <ValueCell
              isLoading={isDataLoading}
              isPercentage={percentages.includes(item.key)}
              itemKey={item.key}
              value={row.original[item.key]}
            />
          );
        },
        header: item.title,
        Header: (
          <TextWithInfo
            textColor={['grey', 800]}
            title={item.title}
            tooltip={item.tooltip || ''}
            variant='font14Bold'
          />
        ),
        size: item.size,
      });
    });
  }, [columnHelper, isDataLoading]);

  const rows = useMemo(() => {
    if (!searchText) {
      return data;
    }
    return data.filter(item => {
      const text = searchText.toLowerCase();
      const hasName = item.label_name.toLowerCase().includes(text);
      const hasDescription = item.description.toLowerCase().includes(text);

      return hasName || hasDescription;
    });
  }, [searchText, data]);

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  return (
    <TriageModelTable<LabelMetrics, string | number>
      columns={columns}
      Control={null}
      data={rows}
      FilterComponent={null}
      initialSorting={[{ desc: false, id: 'label_name' }]}
      initialState={{
        columnPinning: {
          left: ['label_name'],
        },
      }}
      isLoading={isDataLoading}
      onSearch={handleSearch}
      renderEmptyRowsFallback={() => <div></div>}
    />
  );
};

export default SimulationLabelsTable;
