import { useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';

import {
  DateRangeFilterButton,
  FilterButton,
  theme,
} from '@forethought-technologies/forethought-elements';
import DiscoverLastUpdatedAt from '../common/discover-last-updated-at';
import { getMetricFromMetricsParam } from '../discover-all-topics-page/AllTopicsTableV3/helpers';
import DashboardTabBanner from './components/DashboardTabBanner';
import DashboardTabBookmarkedTable from './components/DashboardTabBookmarkedTable';
import DashboardTabCommonTopicsCard from './components/DashboardTabCommonTopicsCard';
import DashboardTabEmpty from './components/DashboardTabEmpty';
import DashboardTabMetricCards from './components/DashboardTabMetricCards';
import DashboardTabTopMoversCard from './components/DashboardTabTopMoversCard';
import NotificationBanner from './components/NotificationBanner';
import { DateRange } from './types';
import AnalyticsFilter from 'src/components/analytic-filter';
import { TopicPeriodicalFilter } from 'src/components/app/types';
import DiscoverErrorPage from 'src/components/discover-error-page';
import FilterButtonIcon from 'src/components/discover-filter-button-icon/FilterButtonIcon';
import {
  datePickerRangeOptions,
  DISCOVER_SHARED_PARAM_NAMES,
  initialDateRangeValue,
  initialMetricFiltersDropdownValueV2,
  initialPeriodicalDropdownValue,
  periodicalFilterOptions,
  timeFilterOptions,
} from 'src/constants/discover';
import { useDataFilter } from 'src/hooks/discover/useDataFilter';
import { useGetDashboardMetrics } from 'src/hooks/discover/useGetDashboardMetrics';
import { useGetMetadata } from 'src/hooks/discover/useGetMetadata';
import useOrgConfig from 'src/hooks/discover/useOrgConfig';
import { usePersistSearchParams } from 'src/hooks/discover/usePersistSearchParams';
import {
  useEmitTrackingEvent,
  useEmitTrackingEventCallback,
  useStateParams,
} from 'src/hooks/hooks';
import { useGenerateLinkWithSearch } from 'src/hooks/useGenerateLinkWithSearch';
import { DiscoverTopicAggregatedMetricType } from 'src/reducers/discoverReducer/types';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import {
  createMetricParam,
  dateRangeDeserialize,
  dateRangeSerialize,
  dateRangeToTimeFilter,
  genericParameterValidator,
  genericSerializeAndDeserialize,
  isTimePeriodValid,
  listDeserialize,
  listSerialize,
  metricFiltersValidatorV2,
  overrideDiscoverSearchParams,
  replaceIdInRoute,
  shouldShowDataAnalyticFilter,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';
import { last30DaysTimeRange } from 'src/utils/timeRangeHelpers';

const DiscoverDashboardPage = () => {
  usePersistSearchParams();
  const linkDetailedWithSearch = useGenerateLinkWithSearch(
    Routes.DISCOVER_TOPIC_DETAIL,
  );
  const linkWithSearch = useGenerateLinkWithSearch(Routes.DISCOVER_TOPICS);
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: initialDateRangeValue,
    paramsName: DISCOVER_SHARED_PARAM_NAMES.TIME_FILTER,
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator(timeFilterOptions),
  });

  const { taxonomyVersion } = useOrgConfig();

  const [timePeriod, setTimePeriod] = useStateParams<TopicPeriodicalFilter>({
    deserialize: genericSerializeAndDeserialize as (
      str: string,
    ) => TopicPeriodicalFilter,
    initialState: initialPeriodicalDropdownValue,
    paramsName: DISCOVER_SHARED_PARAM_NAMES.PERIODICAL_FILTER,
    serialize: genericSerializeAndDeserialize,
    validator: genericParameterValidator(periodicalFilterOptions),
  });

  const {
    dataFilterQuery,
    isLoading: isFilterLoading,
    queryFilterOptions,
    queryFilters,
    setQueryFilters,
  } = useDataFilter();

  const timeFilter = dateRangeToTimeFilter(dateRange);
  const [metricFiltersV2] = useStateParams<DiscoverTopicAggregatedMetricType[]>(
    {
      deserialize: listDeserialize,
      initialState: initialMetricFiltersDropdownValueV2,
      paramsName: DISCOVER_SHARED_PARAM_NAMES.METRIC_FILTERS_V2,
      serialize: listSerialize,
      validator: metricFiltersValidatorV2(),
    },
  );

  const { data, error, loading } = useGetDashboardMetrics({
    dataFilterQuery,
    interval: timePeriod,
    timeFilter,
  });

  const { data: metadata, loading: isMetadataLoading } = useGetMetadata();

  useEmitTrackingEvent('discover-dashboard-viewed');
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const handleDateRangeOnChange = (dateRange: DateRange) => {
    const timeFilter = dateRangeToTimeFilter(dateRange);
    setDateRange(dateRange);
    if (!isTimePeriodValid(timePeriod, timeFilter)) {
      setTimePeriod('daily');
    }
    emitTrackingEventCallback('discover-time-frame-selected', {
      page: 'Discover Dashboard',
      'time-period-selected': timeFilter.isCustom ? 'custom' : timeFilter.key,
    });
  };

  const volumeData = data?.overall_metrics.metrics.find(
    metric => metric?.name === 'volume',
  );

  const lastUpdateAt = data?.last_update_at;

  if (error) {
    return <DiscoverErrorPage errorType={error?.error_type} />;
  }
  return (
    <Box bgcolor={theme.palette.colors.white} flex={1} px={5}>
      <NotificationBanner
        generatedArticleCount={data?.generated_article_count ?? 0}
        isLoading={loading}
      />
      {data?.banner_data && <DashboardTabBanner data={data.banner_data} />}
      <Box alignItems='center' display='flex' flexWrap='wrap' gap={1} my={3}>
        <Box
          data-appcues-target={getAppCuesId({
            componentType: 'button',
            featureName: 'dashboard',
            pageName: 'discover',
            subType: 'daterangefilter',
          })}
        >
          <DateRangeFilterButton
            initialValue={last30DaysTimeRange}
            onChange={dateRange => handleDateRangeOnChange(dateRange)}
            options={datePickerRangeOptions}
            value={timeFilter.value}
          />
        </Box>
        <Box
          data-appcues-target={getAppCuesId({
            componentType: 'button',
            featureName: 'dashboard',
            pageName: 'discover',
            subType: 'timeperiodfilter',
          })}
        >
          <FilterButton
            aria-label='time period filter'
            disabledTooltipMessage='Time period not valid for selected time range'
            initialValue={initialPeriodicalDropdownValue}
            onChange={value => {
              emitTrackingEventCallback('discover-time-comparison-selected', {
                page: 'Discover Dashboard',
                'time-comparison-selected': value,
              });

              setTimePeriod(value as TopicPeriodicalFilter);
            }}
            options={periodicalFilterOptions.map(option => {
              const isDisabled = !isTimePeriodValid(option.value, timeFilter);
              return { ...option, disabled: isDisabled };
            })}
            startAdornment={<FilterButtonIcon dropdownType='time-period' />}
            value={timePeriod}
          />
        </Box>
        {shouldShowDataAnalyticFilter(queryFilterOptions) && (
          <Box
            data-appcues-target={getAppCuesId({
              componentType: 'button',
              featureName: 'dashboard',
              pageName: 'discover',
              subType: 'analyticsfilters',
            })}
          >
            <AnalyticsFilter
              filters={queryFilters}
              handleFilterUpdate={setQueryFilters}
              isLoading={isFilterLoading}
              options={queryFilterOptions ?? []}
              page='dashboard'
            />
          </Box>
        )}
        {lastUpdateAt && <DiscoverLastUpdatedAt lastUpdateAt={lastUpdateAt} />}
      </Box>
      {volumeData?.value === 0 && !loading ? (
        <DashboardTabEmpty />
      ) : (
        <>
          <DashboardTabMetricCards
            isMetadataLoading={isMetadataLoading}
            metadata={metadata}
            onClick={newMetricFilter => {
              navigate(
                overrideDiscoverSearchParams(linkWithSearch, {
                  met: createMetricParam(
                    metricFiltersV2,
                    getMetricFromMetricsParam(newMetricFilter) as string,
                  ),
                  metrics: listSerialize(newMetricFilter as string[]),
                }),
              );
            }}
            timeFilter={timeFilter}
            timePeriod={timePeriod}
          />
          <Grid container mt={0} spacing={3}>
            <Grid item lg={6} md={12} sm={12}>
              <DashboardTabTopMoversCard
                isMetadataLoading={isMetadataLoading}
                onMetricClick={newMetricFilter => {
                  navigate(
                    overrideDiscoverSearchParams(linkWithSearch, {
                      'is-grouped': 'false',
                      met: createMetricParam(metricFiltersV2, newMetricFilter),
                      metrics: listSerialize([
                        `${newMetricFilter}.value`,
                        `${newMetricFilter}.value_changed`,
                      ]),
                    }),
                  );
                }}
                timeFilter={timeFilter}
                timePeriod={timePeriod}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={12}>
              <DashboardTabCommonTopicsCard
                onClick={() => {
                  navigate(overrideDiscoverSearchParams(linkWithSearch));
                }}
                onTopicClick={({ topic_id, topic_name }) => {
                  navigate(
                    overrideDiscoverSearchParams(
                      replaceIdInRoute(linkDetailedWithSearch, topic_id),
                      {
                        name: topic_name,
                        source: 'Discover',
                      },
                    ),
                  );
                }}
                timeFilter={timeFilter}
                timePeriod={timePeriod}
              />
            </Grid>
          </Grid>
          {taxonomyVersion !== 'V2' && (
            <Box mt={3}>
              <DashboardTabBookmarkedTable
                isMetadataLoading={isMetadataLoading}
                metadata={metadata}
                timeFilter={timeFilter}
                timePeriod={timePeriod}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default DiscoverDashboardPage;
