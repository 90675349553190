import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { Box, styled } from '@mui/material';

import {
  Button,
  SelectDropdown,
  theme,
  Toggle,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  addAction,
  getHandoffCustomization,
  updateActionSettings,
} from '../../../../slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { useGetUndefinedContextVariableIdsOnCurrentAction } from '../../hooks/useGetUndefinedContextVariableIdsOnCurrentAction';
import { topArticlesNumberOptions } from '../article-suggestion-settings-panel/constants';
import { KnowledgeConfigurationFields } from '../knowledge-configuration-fields';
import { ArticleSourceComponent } from './ArticleSourceComponent';
import isEqual from 'lodash/fp/isEqual';
import pullAt from 'lodash/fp/pullAt';
import set from 'lodash/fp/set';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import plusIcon from 'src/assets/images/btn-plus.svg';
import { formatArticleSourceQueries } from 'src/components/customization-panel/article-suggestion-settings-panel/formatArticleSourceQueries';
import { validateKnowledgeConfigurationPrompt } from 'src/components/knowledge-configuration-prompt-text-field/helpers';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  CanvasWorkflowBuilderState,
  selectAddingAction,
  selectHandoffCustomization,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import {
  ArticleSource,
  GranularArticleSuggestionActionV2Settings,
} from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface GranularArticleSuggestionV2SettingsPanelProps {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (areActionSettingsUnsaved: boolean) => void;
}

export const GranularArticleSuggestionV2SettingsPanel: FC<
  React.PropsWithChildren<GranularArticleSuggestionV2SettingsPanelProps>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();

  const canvasState: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const addingAction = useSelector(selectAddingAction);
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });
  const undefinedCvs = useGetUndefinedContextVariableIdsOnCurrentAction();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  const isIRStreamEnabled = featureFlags.includes('ir_stream');
  const isInstructionalNoteUIEnabled = featureFlags.includes(
    'instructional_notes_for_kr',
  );
  const isGenerativeAIToggleEnabled = featureFlags.includes(
    'generative_ai_toggle',
  );

  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();

  // generate the default settings for when creating a new granular article suggestion action
  const defaultSettings = useSelector(
    selectHandoffCustomization,
  ) as Partial<GranularArticleSuggestionActionV2Settings>;
  const defaultGranularArticleSuggestionSettings = useMemo(
    () => ({
      allow_generative_ai: false,
      article_sources: [{ doc_type: null, filter_queries: [] }],
      available_article_sources: [],
      is_last_element: false,
      knowledge_configuration_prompt: '',
      question: '',
      read_article_action: 'new_tab',
      read_article_action_options: [],
      should_override_global_knowledge_configuration_prompt: false,
      top_n_articles: 1,
      ...defaultSettings,
    }),
    [defaultSettings],
  );

  const settings = addingAction
    ? defaultGranularArticleSuggestionSettings
    : (canvasState?.actionSettings
        .configuration_fields as GranularArticleSuggestionActionV2Settings);

  // Creating a new state so we can keep track of what has changed
  const [newSettings, setNewSettings] =
    useState<GranularArticleSuggestionActionV2Settings>(settings);

  const isPristine = isEqual(settings, newSettings);
  const [areErrorsVisible, setAreErrorsVisible] = useState(false);

  useEffect(() => {
    setAreActionSettingsUnsaved(!isPristine);
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (!canvasState.isLoadingCustomization) {
      setNewSettings(
        addingAction ? defaultGranularArticleSuggestionSettings : settings,
      );
    }
  }, [
    canvasState.isLoadingCustomization,
    settings,
    addingAction,
    defaultGranularArticleSuggestionSettings,
  ]);

  useEffect(() => {
    if (addingAction) {
      dispatch(
        getHandoffCustomization(
          ActionBuilderActionTypes.GRANULAR_ARTICLE_SUGGESTION_V2,
        ),
      );
    }
  }, [dispatch, addingAction]);

  const getShouldShowErrors = () =>
    newSettings.article_sources.some(articleSource =>
      articleSource.filter_queries.some(
        filterQuery =>
          !filterQuery.value ||
          !filterQuery.field ||
          (typeof filterQuery.value.value === 'string' &&
            filterQuery.value.value.trim() === ''),
      ),
    );

  const handleAddArticleSource = () => {
    setNewSettings({
      ...newSettings,
      article_sources: [
        ...newSettings.article_sources,
        { doc_type: null, filter_queries: [] },
      ],
    });
  };

  const handleUpdateArticleSource = (
    articleSourceIndex: number,
    updatedArticleSource: ArticleSource,
  ) => {
    const updatedSettings = set(
      ['article_sources', articleSourceIndex],
      updatedArticleSource,
      newSettings,
    );

    setNewSettings(updatedSettings);
  };

  const handleDeleteArticleSource = (articleSourceIndex: number) => {
    const updatedSettings = set(
      ['article_sources'],
      pullAt([articleSourceIndex], newSettings.article_sources),
      newSettings,
    );
    setNewSettings(updatedSettings);
  };

  if (canvasState.isLoadingCustomization || !settings || !newSettings) {
    return <LoadingSkeleton />;
  }

  const availableArticleSources = newSettings.available_article_sources;
  const validationError = validateKnowledgeConfigurationPrompt(
    newSettings.knowledge_configuration_prompt ?? '',
  );

  return (
    <ContentWrapper useFullHeight={false}>
      <Box display='flex' flexDirection='column' gap={3}>
        {isIRStreamEnabled && isInstructionalNoteUIEnabled && (
          <>
            <KnowledgeConfigurationFields
              error={validationError}
              knowledgeConfigurationPrompt={
                newSettings.knowledge_configuration_prompt ?? ''
              }
              onChangeKnowledgeConfigurationPrompt={e => {
                setNewSettings({
                  ...newSettings,
                  knowledge_configuration_prompt: e.target.value,
                });
              }}
              onToggleShouldOverrideGlobal={e => {
                setNewSettings({
                  ...newSettings,
                  should_override_global_knowledge_configuration_prompt:
                    e.target.checked,
                });
              }}
              shouldOverrideGlobal={Boolean(
                newSettings.should_override_global_knowledge_configuration_prompt,
              )}
            />
            <Separator />
          </>
        )}
        <Box display='flex' flexDirection='column' gap={1}>
          <Box display='flex' gap={0.5}>
            <Typography variant='font14Medium'>Question</Typography>
            <Typography color={theme.palette.colors.grey[400]} variant='font14'>
              Required
            </Typography>
          </Box>
          <SelectDropdown
            id='questionContextVariableId'
            onChange={e => {
              setNewSettings({
                ...newSettings,
                question: e.target.value,
              });
            }}
            options={contextVariables.map(cv => ({
              color: undefinedCvs.includes(cv.context_variable_id)
                ? theme.palette.colors.red[500]
                : theme.palette.text.primary,
              label: `$${cv.context_variable_name}`,
              value: cv.context_variable_id,
            }))}
            value={newSettings.question}
          />
        </Box>
        <Separator />
        {isGenerativeAIToggleEnabled && (
          <>
            <Box marginX={2}>
              <Toggle
                aria-label='Enable Generative AI'
                checked={newSettings.allow_generative_ai}
                label={
                  <Box alignItems='center' display='inline-flex' marginX={2}>
                    Generative AI
                  </Box>
                }
                onChange={e =>
                  setNewSettings({
                    ...newSettings,
                    allow_generative_ai: e.target.checked,
                  })
                }
              />
            </Box>
            <Separator />
          </>
        )}
        <Box alignItems='center' display='flex' gap={1.5} whiteSpace='nowrap'>
          <Typography variant='font14Medium'>Show top</Typography>
          <Box width={80}>
            <SelectDropdown
              id='showTopNArticles'
              onChange={e => {
                setNewSettings({
                  ...newSettings,
                  top_n_articles: parseInt(e.target.value),
                });
              }}
              options={topArticlesNumberOptions}
              value={newSettings.top_n_articles.toString()}
            />
          </Box>
          <Typography variant='font14Medium'>Articles</Typography>
        </Box>
        <Separator />
        <Box display='flex' flexDirection='column' gap={3}>
          <Box display='flex' flexDirection='column' gap={1}>
            <Box display='flex' gap={0.5}>
              <Typography variant='font14Medium'>
                Read Article Action
              </Typography>
            </Box>
            <SelectDropdown
              id='readArticleAction'
              onChange={e => {
                setNewSettings({
                  ...newSettings,
                  read_article_action: e.target.value,
                });
              }}
              options={newSettings.read_article_action_options}
              value={newSettings.read_article_action}
            />
          </Box>
        </Box>
        <Separator />
        <Box display='flex' flexDirection='column' gap={3}>
          {newSettings.article_sources.map(
            (articleSource, articleSourceIndex) => {
              return (
                <Box
                  display='flex'
                  flexDirection='column'
                  gap={3}
                  key={articleSourceIndex}
                >
                  <ArticleSourceComponent
                    areErrorsVisible={areErrorsVisible}
                    articleSource={articleSource}
                    articleSourceIndex={articleSourceIndex}
                    availableArticleSources={availableArticleSources}
                    handleDeleteArticleSource={handleDeleteArticleSource}
                    handleUpdateArticleSource={handleUpdateArticleSource}
                    isDeleteDisabled={newSettings.article_sources.length === 1}
                  />
                  <Separator />
                </Box>
              );
            },
          )}
        </Box>
        <Button
          onClick={() => {
            handleAddArticleSource();
          }}
          startIcon={<PlusIcon src={plusIcon} />}
          variant='ghost'
        >
          Add article source
        </Button>
      </Box>

      <Box display='flex' gap={2} justifyContent={'flex-end'} marginTop={6}>
        <Button
          onClick={() => {
            onDiscard();
          }}
          variant='secondary'
        >
          Cancel
        </Button>
        <Button
          disabled={hasWorkflowConflict || Boolean(validationError)}
          onClick={() => {
            if (getShouldShowErrors()) {
              setAreErrorsVisible(true);
              return;
            }
            setAreErrorsVisible(false);
            const newConfigFields = {
              ...newSettings,
              article_sources: formatArticleSourceQueries(
                newSettings.article_sources,
                newSettings.available_article_sources,
              ),
            };
            dispatch(
              addingAction
                ? addAction({
                    ...addingAction,
                    data: {
                      ...addingAction.data,
                      configuration_fields: newConfigFields,
                    },
                  })
                : updateActionSettings({
                    action_type: canvasState.actionSettings
                      .action_type as string,
                    configuration_fields: newConfigFields,
                  }),
            );
            dispatchActionEditedTrackingEvent({
              actionType:
                ActionBuilderActionTypes.GRANULAR_ARTICLE_SUGGESTION_V2,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
          variant='main'
        >
          Save
        </Button>
      </Box>
    </ContentWrapper>
  );
};

const ContentWrapper = styled('div')<{
  useFullHeight: boolean;
}>`
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0;
  height: calc(100vh - 160px);
  border-radius: 8px;
  justify-content: space-between;
`;

const Separator = styled('div')`
  width: 100%;
  height: 1px;
  background-color: ${theme.palette.colors.slate[200]};
`;

const PlusIcon = styled(ReactSVG)`
  [fill] {
    fill: ${theme.palette.colors.purple[500]};
  }
  [stroke] {
    stroke: ${theme.palette.colors.purple[500]};
  }
`;
