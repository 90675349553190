import { formatUnixTimestampToDateAndTime } from '@forethought-technologies/forethought-elements';
import {
  FILTER_CORRECT_PREDICTION_KEY,
  FILTER_KEY_FINALIZED_LABEL,
  FILTER_KEY_PREDICTED_LABEL,
  FILTER_REASON_KEY,
  FILTER_STATUS_KEY,
} from './constants';
import invert from 'lodash/invert';
import { NA } from 'src/constants/solve';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { TicketMetrics, TriageTicket } from 'src/services/triage/types';

export const formatRowValue = (
  key: keyof TriageTicket | keyof TicketMetrics,
  value: string | number | boolean | null,
) => {
  if (key === 'timestamp') {
    return value ? formatUnixTimestampToDateAndTime(value as number) : NA;
  }
  return value;
};

export const getPercentagePart =
  (total?: number | null) => (part?: number | null) => {
    if (part === 0) {
      return '0%';
    }

    if (!part || !total) {
      return NA;
    }

    const computedValue = ((part / total) * 100).toFixed(2);

    return `${computedValue}%`;
  };

export const createLabelFiltersOptions = (
  model: VersionedTriageModel,
  prefix: string,
) => {
  const { value_mapping } = model.model.model_output_formatter;

  if (!value_mapping) {
    return [];
  }

  return Object.entries(value_mapping).map(item => {
    const [key, value] = item;
    return {
      label: key,
      value: `${prefix}${value}`,
    };
  });
};

export const getLabelObj = (model: VersionedTriageModel) => {
  const labels = model.model.model_output_formatter.value_mapping;
  if (!labels) {
    return null;
  }
  return invert(labels);
};

const handleSearch = (
  searchText: string,
  searchType: string,
  item: TicketMetrics,
) => {
  if (!searchText) {
    return true;
  }
  if (searchType === 'keyword') {
    return (
      item.title?.toLowerCase().includes(searchText) ||
      item.body?.toLowerCase().includes(searchText)
    );
  }
  return item.ticket_id.includes(searchText);
};

export const clientFilter = (
  searchText: string,
  searchType: string,
  filters: string[],
  tickets?: TicketMetrics[],
) => {
  if (!searchText && !filters.length) {
    return tickets;
  }

  if (!tickets) {
    return [];
  }

  return tickets.filter(item => {
    const filterStatus = filters.filter(filterItem =>
      filterItem.includes(FILTER_STATUS_KEY),
    );
    const isValidStatus = !filterStatus.length
      ? true
      : filterStatus.includes(`${FILTER_STATUS_KEY}${item.status}`);

    const filterFinalizedLabel = filters.filter(filterItem =>
      filterItem.includes(FILTER_KEY_FINALIZED_LABEL),
    );
    const isValidFinalziedLabel = !filterFinalizedLabel.length
      ? true
      : filterFinalizedLabel.includes(
          `${FILTER_KEY_FINALIZED_LABEL}${item.final_label}`,
        );

    const filterPredictedLabel = filters.filter(filterItem =>
      filterItem.includes(FILTER_KEY_PREDICTED_LABEL),
    );
    const isValidPredictedLabel = !filterPredictedLabel.length
      ? true
      : filterPredictedLabel.includes(
          `${FILTER_KEY_PREDICTED_LABEL}${item.predicted_label}`,
        );

    const filterCorrectPrediction = filters.filter(filterItem =>
      filterItem.includes(FILTER_CORRECT_PREDICTION_KEY),
    );
    const isValidCorrectPrediction = !filterCorrectPrediction.length
      ? true
      : filterCorrectPrediction.includes(
          `${FILTER_CORRECT_PREDICTION_KEY}${item.is_correct ? 'yes' : 'no'}`,
        );

    const filterReason = filters.filter(filterItem =>
      filterItem.includes(FILTER_REASON_KEY),
    );
    const isValidReason = !filterReason.length
      ? true
      : filterReason.includes(
          `${FILTER_REASON_KEY}${item.reason_no_prediction}`,
        );

    const isValidSearch = handleSearch(searchText, searchType, item);

    return (
      isValidStatus &&
      isValidFinalziedLabel &&
      isValidPredictedLabel &&
      isValidCorrectPrediction &&
      isValidReason &&
      isValidSearch
    );
  });
};

export const getAccuracy = (tickets?: TriageTicket[]) => {
  if (!tickets?.length) {
    return 0;
  }
  const total = tickets.filter(item => item.predicted_label).length;
  const correctPredcitons = tickets.filter(
    item => item.is_prediction_accurate,
  ).length;
  if (!total || !correctPredcitons) {
    return 0;
  }
  return correctPredcitons / total;
};
