import React from 'react';
import { Box, SelectChangeEvent, useTheme } from '@mui/material';

import {
  Badge,
  ListOption,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { isModelReadOnly } from '../dashboard-pages/triage-model-detail-page/helpers';
import LabelInput from './LabelInput';
import {
  CurrentOrgHelpdeskDetail,
  PatchTriageModelRequest,
  TagDefinitionResponse,
  VersionedTriageModel,
} from 'src/reducers/triageSettingsReducer/types';

interface LabelItemProps {
  currentPatchModel: PatchTriageModelRequest['body'];
  handleLabelMappingChange: (
    e: SelectChangeEvent<string>,
    label: TagDefinitionResponse,
  ) => void;
  helpdesk: CurrentOrgHelpdeskDetail | null;
  isLoadingField: boolean;
  label: TagDefinitionResponse;
  outputFieldLabelOptions?: ListOption[];
  targetedModel: VersionedTriageModel | undefined;
}
const LabelItem: React.FC<LabelItemProps> = ({
  currentPatchModel,
  handleLabelMappingChange,
  helpdesk,
  isLoadingField,
  label,
  outputFieldLabelOptions,
  targetedModel,
}) => {
  const { palette } = useTheme();
  // Label that we are currently trying to get a mapping for
  const currentPatchLabel = currentPatchModel?.labels?.find(
    l => l.tag_id === label.tag_id,
  );
  const defaultField =
    targetedModel?.model.model_output_formatter.not_predicted_value;
  const isDefaultField = defaultField === label.title.trim();
  // Get the value of the dropdown, based on the current changes,
  // the live model, or the label, or nothing
  const value =
    currentPatchLabel?.output_field_value || label.output_field_value || '';
  const disabled =
    isModelReadOnly(targetedModel) || !outputFieldLabelOptions?.length;

  return (
    <Box
      alignItems='center'
      borderBottom={`1px solid ${palette.colors.slate[200]}`}
      display='flex'
      gap='70px'
      justifyContent='space-between'
      key={label.tag_id}
      p='12px 130px 12px 0'
    >
      <Box alignItems='center' display='flex' flex={0.35} gap='8px'>
        <Typography color={palette.colors.grey[800]} variant='font16'>
          {label.title}
        </Typography>
        {isDefaultField && <Badge label='Default label' variant='macro' />}
      </Box>
      <Box flex={0.65}>
        <LabelInput
          disabled={disabled}
          handleLabelMappingChange={handleLabelMappingChange}
          helpdesk={helpdesk}
          isLoadingField={isLoadingField}
          label={label}
          outputFieldLabelOptions={outputFieldLabelOptions}
          value={value}
        />
      </Box>
    </Box>
  );
};

export default LabelItem;
