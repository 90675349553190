import Box from '@mui/material/Box';

import { LAST_14_DAYS_INDEX } from '../triage-model-detail-page/constants';
import EmptyLabelsTable from '../triage-model-detail-page/label-tab/EmptyLabelsTable';
import NotFound from '../triage-version-control-detail/NotFound';
import SimulationLabelsOverview from './SimulationLabelsOverview';
import SimulationLabelsTable from './SimulationLabelsTable';
import Spinner from 'src/components/spinner';
import {
  DISCOVER_SHARED_PARAM_NAMES,
  timeFilterOptions,
} from 'src/constants/discover';
import { useStateParams } from 'src/hooks/hooks';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import {
  useGetModelByIdQuery,
  useGetPredictionTestResultsQuery,
} from 'src/services/triage/triageApi';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { datePickerRangeOptions } from 'src/utils/timeRangeHelpers';

const SimulationLabelsTab = ({
  modelId,
}: {
  model: VersionedTriageModel;
  modelId?: string;
}) => {
  const {
    data: currentVersionedModel,
    isError,
    isLoading: isModelLoading,
  } = useGetModelByIdQuery(modelId || '');
  const [dateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: datePickerRangeOptions[LAST_14_DAYS_INDEX].value,
    paramsName: DISCOVER_SHARED_PARAM_NAMES.TIME_FILTER,
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator(timeFilterOptions),
  });
  const predictionTestId = window.location.pathname.split('/')[2];
  const {
    data: tableData,
    isFetching: isTableFetching,
    isLoading: isTableLoading,
  } = useGetPredictionTestResultsQuery(predictionTestId);
  const { labels_metrics: labelTableData = [] } = tableData || {};

  const isLoading = isModelLoading || isTableLoading;
  if (isLoading) {
    return <Spinner />;
  }

  if (!currentVersionedModel || !tableData?.label_overall_metrics || isError) {
    return <NotFound />;
  }

  const isEmpty = !labelTableData.length;
  const grid = isEmpty ? 'auto 110px auto' : 'auto minmax(350px, auto)';

  return (
    <Box display='grid' gridTemplateRows={grid} rowGap={5}>
      <SimulationLabelsOverview
        data={tableData.label_overall_metrics}
        dateRange={dateRange}
      />
      <SimulationLabelsTable
        data={labelTableData}
        isDataLoading={isTableFetching || isTableLoading}
      />
      {isEmpty && <EmptyLabelsTable />}
    </Box>
  );
};

export default SimulationLabelsTab;
